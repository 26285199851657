<template>
  <v-container>
    <v-row
      v-for="(input,k) in params"
      :key="k"
    >
      <v-col
        cols="12"
        sm="5"
        md="5"
      >
        <v-text-field
          v-model="input.reward"
          prepend-icon="mdi-currency-usd"
          label="Reward $"
          hide-details="auto"
        />
      </v-col>
      <v-col
        cols="12"
        sm="5"
        md="5"
      >
        <v-text-field
          v-model="input.score"
          prepend-icon="mdi-counter"
          label="Score %"
          hide-details="auto"
        />
      </v-col>
      <v-col
        sm="1"
        md="1"
        align-self="center"
      >
        <v-btn
          v-show="k || ( !k && params.length > 1)"
          class="mx-2"
          fab
          dark
          x-small
          color="red"
          @click="remove(k)"
        >
          <v-icon dark>
            mdi-minus
          </v-icon>
        </v-btn>
      </v-col>
      <v-col
        sm="1"
        md="1"
        align-self="center"
      >
        <v-btn
          v-show="k === params.length-1"
          class="mx-2"
          fab
          dark
          x-small
          color="green"
          @click="add(k)"
        >
          <v-icon dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Params',
  props: {
    params: {
      type: Array,
      required: true
    }
  },
  methods: {
    add () {
      const params = this.params
      params.push({ reward: '', score: '' })
      this.$emit('input', params)
    },
    remove (index) {
      const params = this.params
      params.splice(index, 1)
      this.$emit('input', params)
    }
  }
}
</script>
